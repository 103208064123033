/** Beginning of Utility.js */

function removeTags(str) {
  const  rex = /(<([^>]+)>)/ig;
  return str.replace(rex,'')
                  .replace(/&#8211;/g,'-')
                  .replace(/&#8217;/g,"'")
                  .replace(/&nbsp;/g,' ')
                  .replace(/\[&hellip;\]/g, '...')
                  ;
};

function camelCaseToSnakeFormat(str, glue = '_') {
  return str.replace(/([a-z])([A-Z])/g, `$1${glue}$2`).toLowerCase();
};

function acronym(str, len = 2) {
  const str_formatted = camelCaseToSnakeFormat(str);
  let acronymWord = '';
  str_formatted.split('_').forEach(word => {
      acronymWord += word.substring(0, len);
  });
  return acronymWord;
};

function cardIcon(cardType) {
      switch (cardType){
        case 'visa':
        case 'mastercard':
        case 'jcb':
        case 'discover':
          return `cc-${cardType}`;
        case 'americaExpress':
            return `cc-${acronym(cardType, 2)}`;
        case 'dinersClub':
            return 'cc-diners-club';
        case 'verve':
        case 'credit':
            return 'credit-card';
        default:
            return true;
      }
};

function creditCardFormat(str) {
  const str_formrt = str.replace(/ /g,'')
                          .replace(/\d{4}/g, (match) => (`${match} `)).trim();
  return str_formrt;
};

function strPad(str, len, pad = '*',  direction = 'left') {
  const strLen = str.length;
  let start;
  let end;
  switch (direction){
      case 'right':
              start = len >= strLen ? 0 : strLen - len;
              end = strLen;
          break;
      case 'middle':
              start = len >= strLen ? 0 : Math.floor((strLen - len) / 2);
              end = len >= strLen ? strLen : start + len;
          break;
      default:
          start = 0;
          end = len >= strLen ? strLen : len;
  }
  const zeroIndexStart = start;
  const zeroIndexEnd = end;
  const arrLen = len <= strLen ? strLen : len;
  const arrPadding = [...Array(arrLen)].map((_, i) => {
      return  i >= zeroIndexStart && i < zeroIndexEnd ? pad : str[i];
  });

  return arrPadding.join('');
};

function leadZero(str, len, pad = '0',  direction = 'left') {
  const strConvert = `${str}`;
  const strLen = strConvert.length;
  let start, arrLen;
  if (strLen >= len){
    start = 0;
    arrLen = strLen;
  } else {
    start = direction === 'right' ? 0 : len - strLen;
    arrLen = len;
  }
  const arrPadding = [...Array(arrLen)].map((_, i) => {
      const ind = direction === 'left' ? i - start : i;
      return strConvert[ind] === undefined ? pad : strConvert[ind];
  });

  return arrPadding.join('');
};

function isDateFormat(value) {
  return (/[2][0-9]{3}-([0][1-9]|[1][0-2])-([0][1-9]|[1-2][0-9]|[3][0-1])/.test(value));
}
function html2json() {

};


function rangeNum(min, max){
  const minInt = parseInt(min, 10);
  const maxInt = parseInt(max, 10);
  const diff = maxInt > minInt ? maxInt - minInt : 0;
  const rangeArr = [...Array(diff + 1)].map((_, i) => minInt + i);
  return rangeArr;
};




function invalidMinLength(value, min) {
  // console.log(`${value}`.length < min, `${value}`.length, min);
  return `${value}`.length < min;
}

function invalidMaxLength(value, max) {
  return `${value}`.length > max;
}

function invalidAllowExts(value, extensions) {
  return !Array.isArray(extensions) || extensions.indexOf(value) === -1;
}

function invalidEmail(value) {
  return !(/[a-zA-Z0-9._#~&-]+@[a-zA-Z0-9._#~&-]+\.[a-z]{2,}/.test(value));
}

function invalidCompare(value, compareValue) {
  return value !== compareValue;
}

const min = (value, minimum) => {
  return value < minimum;
};

const max = (value, maximum) => {
  return value < maximum;
};

function invalidCard(value){
  /*
    // For Complete Card Validation
    const validCards = {
      americaExpress: /^(?:3)([47])(?:[0-9]{13})$/,
      visa: /^(4)([0-9]{12})(?:[0-9]{3})?$/,
      mastercard: /^(?:5)([1-5])(?:[0-9]{14})$/,
      discover: /^(?:6)(011|5[0-9][0-9])(?:[0-9]{12})$/,
      dinersClub: /^3(0[0-5]|[68][0-9])(?:[0-9]{11})$/,
      jcb: /^(2131|1800|35\d{3})(?:\d{11})$/,
      verve: /^(506)(?:0|1)(?:[0-9]{15})$/,
    };
  */
  // To check the first unique card
  const validShortCards = {
    americaExpress: /^3[47]/,
    visa: /^4/,
    mastercard: /^5[1-5]/,
    discover: /^(6011|5)/,
    dinersClub: /^3(0[0-5]|[68])/,
    jcb: /^(2131|1800|35)/,
    verve: /^506(0|1)/,
  };

  let cardType = 'credit';

  const str = value.replace(/ /g, '');
  // eslint-disable-next-line no-unused-vars
  for ( let key in validShortCards){
    if ((new RegExp(validShortCards[key])).test(str)){
        cardType = key === 'discover' && validShortCards.verve.test(str) ? 'verve' :  key;
      break;
    }
  }
  return {status: cardLengthValid(cardType, str), cardType };
}

function cardLengthValid(card, value){
  switch (card){
    case 'visa':
        return [13, 16].indexOf(value.length) === -1;
    case 'mastercard':
        return [16].indexOf(value.length) === -1;
    case 'americaExpress':
        return [15].indexOf(value.length) === -1;
    case 'discover':
        const lendis = value.indexOf('5') === 0 ? 15 : 16;
        return [lendis].indexOf(value.length) === -1;
    case 'dinersClub':
        return [14].indexOf(value.length) === -1;
    case 'jcb':
        const lenjcb = value.indexOf('35') === 0 ? 16 : 15;
        return [lenjcb].indexOf(value.length) === -1;
    case 'verve':
        return [19].indexOf(value.length) === -1;
    default:
        return true;
  }
}



/**
 *
 * @param name {String} property name
 * @param objectData
 */
function propsAvailable(name, objectData) {
  return Object.prototype.hasOwnProperty.call(objectData, name);
}

// tslint:disable-next-line:no-shadowed-constiable
function propExist(obj, props, depth, max) {
  // props[depth] is each array of properties to check in the object
  // obj[props[depth]] Get the value of the props
  const exist = isEmpty(obj) ? false : propsAvailable(props[depth], obj);
  // Check if the propert exist in the object
  if (!exist) {
      //  if it does not exist return false
      return false;
  }
  const newObj = obj[props[depth]];
  // Go to the child property
  const newDepth = depth + 1;
  // If the depth is attain return false
  // Else check if the child property exist
  return newDepth === max ? true : propExist(newObj, props, newDepth, max);
}

function isEmpty(val, str = false, obj = true) {
  let empty  = val === null || val === undefined;
  if (!empty && str === true) {
      empty = val.trim() === '';
  }

  if (!empty &&  obj === true){
      empty = Object.keys(val).length === 0 && val.constructor === Object;
  }
  return empty;
}

function deepPropsExist(obj, ...props) {
  if (isEmpty(obj) || Object.prototype.toString.call(obj) !== '[object Object]') {
      return null;
  }

  const depth = props.length;
  return propExist(obj, props, 0, depth);
}

function getObjValue(obj, props, depth, max) {
  // props[depth] is each array of properties to check in the object
  // obj[props[depth]] Get the value of the props
  const exist = isEmpty(obj) ? false : propsAvailable(props[depth], obj);
  // Check if the propert exist in the object
  if (!exist) {
      //  if it does not exist return false
      return null;
  }
  const newObj = obj[props[depth]];
  // Go to the child property
  const newDepth = depth + 1;
  // If the depth is attain return false
  // Else check if the child property exist
  return newDepth === max ? newObj : getObjValue(newObj, props, newDepth, max);
}

function getDeepObjValue(obj, ...props) {
  if (isEmpty(obj) || Object.prototype.toString.call(obj) !== '[object Object]') {
      return null;
  }
  const max = props.length;
  return getObjValue(obj, props, 0, max);
}

function isEqual(value, other) {
  // Get the value type
  const type = Object.prototype.toString.call(value);
  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) {
      return false;
  }

  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) {
      return false;
  }

  // Compare the length of the length of the two items
  const valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
  const otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) {
      return false;
  }

  // Compare properties
  if (type === '[object Array]') {
      for (let i = 0; i < valueLen; i++) {
          if (compare(value[i], other[i]) === false) {
              return false;
          }
      }
  } else {
      // eslint-disable-next-line no-unused-vars
      for (const key in value) {
          if (value.hasOwnProperty(key)) {
              if (compare(value[key], other[key]) === false) {
                  return false;
              }
          }
      }
  }

  // If nothing failed, return true
  return true;

}

// Compare two items
function compare(item1, item2) {

  // Get the object type
  const itemType = Object.prototype.toString.call(item1);

  // If an object or array, compare recursively
  if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) {
          return false;
      }
  } else { // Otherwise, do a simple comparison

      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) {
          return false;
      }

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === '[object Function]') {
          if (item1.toString() !== item2.toString()) {
              return false;
          }
      } else {
          if (item1 !== item2) {
              return false;
          }
      }
  }
  return true;
}

function isObject(obj){
  return Object.prototype.toString.call(obj) === '[object Object]';
}

/** End of Utility.js */

const Validation = function(){
  this.errors = {};
  this.keys = [];
}


Validation.prototype.make = function(data, rules) {
  const validationRule = { ...rules };
  Object.keys(validationRule).forEach((key) => {
    if (
      validationRule[key].includes('required_if_not_empty')
      && isEmpty(data[key])
    ) {delete validationRule[key];}
    rules[key].forEach((rule) => {
      this.processValidation(rule, key, data);
    });
  });
}

Validation.prototype.processValidation = function(rule, key, data){
  const [type, params] = rule.split(':');
  let status = false;
  let message;
  const paramters = !isEmpty(params) ? `${params}`.split('|') : [];
  switch (type) {
    case 'required':
      status = isEmpty(data[key], true);
      message = 'The field can not be empty because it is required';
      break;
    case 'array_required':
      status = !Array.isArray(data[key]) || data[key].length < 1;
      message = 'The field can not be empty because it is required';
      break;
    case 'required_if_not_empty':
      message = '';
      break;
    case 'min':
        status = min(parseInt(data[key], 10), parseInt(paramters[0], 10));
        message = `The field should not be less than ${paramters[0]}`;
        break;
    case 'max':
        status = max(parseInt(data[key], 10), parseInt(paramters[0], 10));
        message = `The field should not be greater than ${paramters[0]}`;
        break;
    case 'min_length':
      status = invalidMinLength(data[key], parseInt(paramters[0], 10));
      message = `The field must not be less than ${paramters[0]}`;
      break;
    case 'max_length':
      status = invalidMaxLength(data[key], parseInt(paramters[0], 10));
      message = `The field must not be greater than ${paramters[0]}`;
      break;
    case 'exact':
      status = data[key].length !== parseInt(paramters[0], 10);
      message = `The field must not be greater than ${paramters[0]}`;
      break;
    case 'email':
      status = invalidEmail(data[key]);
      message = 'The field has invalid email';
      break;
    case 'compare':
      status = invalidCompare(data[key], data[paramters[0]]);
      message = `The field does not match ${paramters[0]} field`;
      break;
    case 'date':
      status = !isDateFormat(data[key]);
      message = `The field does not have the right date format`;
      break;
    case 'enum':
      status = !paramters[0].split(',').includes(data[key]);
      message = `The field can only be ${paramters[0]}`;
      break;
    case 'is_number':
    case 'is_numeric':
      status = isNaN(data[key]);
      message = 'The field can only be a number';
      break;
    case 'is_boolean':
      status = typeof data[key] !== 'boolean';
      message = 'The field can only be a boolean';
      break;
    case 'url':
      status = !/^http(|s):\/\//.test(data[key]);
      message = "The field is not a valid url";
      break;
    case 'card':
      const card = invalidCard(data[key]);
      status = card.status;
      message = card.status ? `The ${camelCaseToSnakeFormat(card.cardType, ' ')} card is not valid|${cardIcon(card.cardType)}` : '';
      break;
    default:
      throw new Error(`${type} validator type can not be use with [${key}] because ${type} does not exist`);
  }

  return this.addError(key, status, message);
}

Validation.prototype.addError = function(key, status, message) {
  if (status && isEmpty(this.errors[key])) {
    this.errors[key] = message;
    this.keys.push(key);
    return true;
  }
  return null;
}

Validation.prototype.getErrors = function() {
  return this.errors;
}

Validation.prototype.getFirstError = function() {
  const [first] = this.keys;
  return !isEmpty(first) ? this.errors[first] : null;
}

Validation.prototype.passes = function() {
  return Object.keys(this.errors).length === 0;
}

Validation.prototype.fails = function() {
  return Object.keys(this.errors).length > 0;
}

